<template>
  <div
    v-if="isOpen"
    ref="modalRef"
    class="modal-overlay"
    @click.self="closeModal"
  >
    <div class="modal" @click.stop>
      <div class="modal-header">
        <slot name="header"></slot>
        <i class="fa fa-close close-icon" @click="closeModal"></i>
      </div>
      <div class="modal-body">
        <slot name="body"></slot>
      </div>
      <div class="modal-footer">
        <button @click="$emit('decline')" class="btn -bordered -sm -gray me-3">
          <slot name="decline-btn-text"> Cancel </slot>
        </button>

        <button @click="$emit('confirm')" class="btn -filled -sm -blue" :disabled="isLoading">
          <slot name="confirm-btn-text">
            <Spinner v-if="isLoading" />
            {{ buttonLabel }}
          </slot>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
const emit = defineEmits(["decline", "confirm"]);
import Spinner from "@/components/Spinner.vue";


const props = defineProps({
  type: String,
  isOpen: Boolean,
  isLoading: {
    type: Boolean,
    default: false,
  },
  buttonLabel: {
    type: String,
    default: "Confirm",
  },
});

const closeModal = () => {
  emit("decline");
};
const modalRef = ref(null);

</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #fff;
  border-radius: 8px;
  width: 80%;
  max-width: 650px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #ddd;
  font-weight: 600;
  font-size: 18px;
}

.close-icon {
  font-size: 24px;
  cursor: pointer;
}

.modal-body {
  padding: 15px;
}
.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
}
</style>
